import React from "react";
import { ReactNode } from "react"
import { Container } from "react-bootstrap";

interface SectionProps {
    children?: ReactNode;
    title?: string;
    background?: string;
}

const Section = (props: SectionProps) => {
    return (
        <div className="pt-3" style={{ backgroundColor: props.background }}>
            <Container className="py-5">
                {props.title !== undefined && <h2 className="pb-2 fs-1 text-center">{props.title}</h2>}
                {props.children}
            </Container>
        </div>
        )
}

export default Section;